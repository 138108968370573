import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import BackArrow from "../../assets/back_arrow.svg";
import { useAppSelector } from "../../utils/hooks/useDispatchHooks";
import Header from "./Header";
import UpArrowIcon from "../../assets/up-arrow.svg";
import DownArrowIcon from "../../assets/down-arrow.svg";

const Layout = () => {
  const location = useLocation();
  const pathName = location.pathname.toString();
  const navigate = useNavigate();
  const { page_number } = useParams<{
    page_number: string;
  }>();
  const { editionDetails } = useAppSelector((state) => state.edition);
  const { magazineName } = useParams<{ magazineName: string }>();
  const [isOPenMenu, setIsOpenMenu] = useState(false);

  const onHandleChangeArticle = (event: any) => {
    if (page_number) {
      navigate(
        `${pathName?.split("/")?.slice(0, -1)?.join("/")}/${
          event?.page_number
        }`,
        { replace: true }
      );
    } else {
      navigate(`${pathName}/${event?.page_number}`, {
        replace: true,
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      setIsOpenMenu(false);
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className=" ">
        <Header />
        <div className="">
          <div className="py-3 sm:py-5 px-2 bg-[#206092] ">
            <div className="container mx-auto w-full grid grid-cols-3">
              <div className="flex items-center ">
                {pathName?.split("/")?.filter((i) => i)?.length > 0 && (
                  <>
                    <img
                      onClick={() => navigate(-1)}
                      src={BackArrow}
                      alt="back-arrow"
                      className="cursor-pointer"
                    />
                    <span
                      onClick={() => navigate(-1)}
                      className="ml-2 text-white cursor-pointer"
                    >
                      Terug
                    </span>
                  </>
                )}
              </div>
              <div className="flex justify-center capitalize text-white text-xl sm:text-4xl">
                {pathName === "/"
                  ? `Vakbladen`
                  : magazineName
                  ? magazineName
                  : `${pathName?.split("/")?.[1]}`}
              </div>
              <div className="text-right">
                {pathName?.split("/")?.length > 3 && (
                  <Menu as="div" className="relative inline-block text-left">
                    <div onClick={() => setIsOpenMenu(!isOPenMenu)}>
                      <Menu.Button className="bg-[#206092] flex items-center text-white border font-bold outline-none border-gray-300 text-sm rounded p-1 sm:p-2.5">
                        Artikelen
                        {isOPenMenu ? (
                          <img
                            src={UpArrowIcon}
                            alt="UpArrowIcon"
                            className="pl-2"
                          />
                        ) : (
                          <img
                            src={DownArrowIcon}
                            alt="DownArrowIcon"
                            className="pl-2"
                          />
                        )}
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-[18rem] sm:w-[28rem] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            <div className="bg-white py-2 px-4 font-[500]">
                              Artikelen
                            </div>
                          </Menu.Item>
                          {editionDetails?.articles?.map(
                            (
                              item: {
                                page_number: number;
                                subtitle: string;
                                title: string;
                              },
                              index: number
                            ) => (
                              <Menu.Item key={index}>
                                <div
                                  onClick={() => onHandleChangeArticle(item)}
                                  className="bg-[#F2F2F2] text-gray-900 py-2 px-4 truncate hover:bg-[#f6f4f4] hover:cursor-pointer"
                                >
                                  {`${item?.title} ${
                                    item?.subtitle ? `- ${item?.subtitle}` : ""
                                  }`}
                                </div>
                              </Menu.Item>
                            )
                          )}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                )}
              </div>
            </div>
          </div>
          <div className="container mx-auto w-full flex justify-between items-center my-4 px-2 sm:px-4 lg:px-4 md:lg:px-4">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
