import { MAGAZINE_URLS } from "../../common/constant/urlConstant";
import { EditionIProps, SearchIProps } from "../../reduce/action/magazine/MagazineAction";
import { apiService } from "../api-interceptor";

export interface MagazineIProps {
  session_id: Number;
  key: string;
}

const MagazineServices = {
  getAllMagazineList: (apiData: MagazineIProps) =>
    apiService.postData(MAGAZINE_URLS.GET_ALL_MAGAZINES_URLS, apiData),
  getMagazines: (apiData: MagazineIProps) =>
    apiService.postData(MAGAZINE_URLS.GET_MAGAZINES_URLS, apiData),
  getEditionsList: (apiData: MagazineIProps) =>
    apiService.postData(MAGAZINE_URLS.GET_EDITIONS_URLS, apiData),
  getEdition: (apiData: EditionIProps) =>
    apiService.postData(MAGAZINE_URLS.GET_EDITION_URLS, apiData),
  searchMagazine: (apiData: SearchIProps) =>
    apiService.postData(MAGAZINE_URLS.SEARCH_MAGAZINE_URLS, apiData),
};
export default MagazineServices;
