import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { API_KEY_ID } from "../../../common/constant/urlConstant";
import { getEditionsList } from "../../../reduce/action/magazine/MagazineAction";
import { getLocalState } from "../../../utils/helpers";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../utils/hooks/useDispatchHooks";
import RightArrowIcon from "../../../assets/right_arrow.svg";

const Editions = () => {
  const { magazineName } = useParams<{ magazineName: string }>();
  const { editionList } = useAppSelector((state) => state.edition);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getEditionsList({
        session_id: JSON.parse(getLocalState("user_info") || "")?.session_id,
        key: API_KEY_ID,
        magazine: magazineName,
      })
    );
  }, [dispatch, magazineName]);

  return (
    <>
      <div className="flex flex-col w-full">
        <div className="text-center mb-12 text-[20px]">
          Tik op een editie om deze te lezen
        </div>
        <div className="sm:mx-auto sm:w-full sm:max-w-4xl">
          <h2 className="text-center mb-12 text-4xl tracking-tight text-black font-bold">
            Edities
          </h2>
          <div className="grid sm:grid-cols-2 gap-4 justify-center text-center">
            {editionList?.map(
              (item: { edition: number; pk: number }, index: number) => (
                <Link
                  key={index}
                  to={`/editions/${magazineName}/${item?.pk}`}
                  className="flex flex-col items-center justify-center w-full cursor-pointer mb-[30px]"
                >
                  <div className="flex flex-row gap-[9px] items-center  w-full">
                  <div className="basis-1/8 flex justify-end">

                    <img
                      className="h-3.5"
                      src={RightArrowIcon}
                      alt="arrow-right"
                    />
                    </div>
                    <div className="basis-7/8">
                    <p className=" sm:text-2xl md:text-[26px] text-xl text-[#206092] tracking-[0.84px] first-letter:uppercase">
                      editie nummer: {item?.edition}
                    </p>
                    </div>
                  </div>
                </Link>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Editions;
