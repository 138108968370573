import axios from 'axios'

const instance = axios.create({
  timeout: 10000,
  params: {},
})

instance.interceptors.request.use(
  async (request: any) => {
    return request
  },
  (error) => {
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  async (response: any) => {
    return response
  },
  (error) => {
    if (error?.response?.status === 401) {
      window.location.href = '/login'
      localStorage.clear()
    } else {
      return Promise.reject(error)
    }
  },
)

export const apiService = {
  request(config = {}) {
    return instance.request(config)
  },
  getData(url: string, config = {}) {
    return instance.get(url, config)
  },
  postData(url: string, data?: any, config?: Record<string, any>) {
    return instance.post(url, data, config)
  },
  putData(url: string, data?: any, config?: Record<string, any>) {
    return instance.put(url, data, config)
  },
  patchData(url: string, data?: any) {
    return instance.patch(url, data)
  },
  deleteData(url: string, config = {}) {
    return instance.delete(url, config)
  },
}
