import { useFormik } from "formik";
import { Link, Navigate, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { getLocalState } from "../../utils/helpers";

import Logo from "../../assets/logo.svg";
import { useAppDispatch } from "../../utils/hooks/useDispatchHooks";
import { authUser } from "../../reduce/action/auth/AuthAction";
import { API_KEY_ID } from "../../common/constant/urlConstant";
import { useEffect, useState } from "react";
import AlertModal from "../../common/modal/ErrorModal";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Voer een geldig e-mailadres in!")
    .required("E-mail is een verplicht veld!"),
  password: Yup.string()
    .required("Wachtwoord is een verplicht veld")
    .min(4, "Wachtwoord moet minimaal 8 tekens lang zijn"),
});

export interface LoginIProps {
  email: string;
  password: string;
  key?: string;
}

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState<{
    isOpen: boolean;
    errorMsg: string;
  }>({
    isOpen: false,
    errorMsg: "",
  });

  const onHandleSubmitForm = (values: LoginIProps) => {
    setIsLoading(true);
    values.key = API_KEY_ID;
    if (getLocalState("user_info")) {
      localStorage.clear();
    }
    dispatch(authUser(values))
      .then(() => {
        setIsLoading(false);
        navigate("/");
      })
      .catch((res) => {
        setShowErrorModal({ isOpen: true, errorMsg: res?.response?.data });
        setIsLoading(false);
      });
  };

  /* close error modal */
  const onHandleCloseModal = () => {
    setShowErrorModal({ isOpen: false, errorMsg: "" });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: onHandleSubmitForm,
  });

  if (getLocalState("user_info")) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <div className="flex h-screen flex-col justify-center px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto w-auto" src={Logo} alt="Your Company" />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md bg-[#206092] px-3 rounded py-8">
          <h2 className="text-center mb-7 text-4xl tracking-tight text-white">
            Login
          </h2>
          <form onSubmit={formik.handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium leading-6 text-white label-required">
                Email
              </label>
              <div className="mt-2">
                <input
                  name="email"
                  style={{backgroundColor:'white'}}
                  placeholder="Email"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  className="block px-2 w-full bg-white-100  rounded-md border-0 sm:py-2.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
                />
              </div>
              {formik.errors.email && formik.touched.email && (
                <span className="text-[#FF8585]">{formik.errors.email}</span>
              )}
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-white label-required"
                >
                  Wachtwoord
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  placeholder="Wachtwoord"
                  style={{backgroundColor:'white'}}
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  className="block px-2 w-full bg-white-100 rounded-md border-0 sm:py-2.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
                />
              </div>
              {formik.errors.password && formik.touched.password && (
                <span className="text-[#FF8585]">{formik.errors.password}</span>
              )}
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-[#b3be1d] px-3 sm:py-2.5 py-1.5 text-sm leading-6 text-black shadow-sm hover:bg-[#cbd624]"
              >
                Login
              </button>
            </div>
          </form>
          <div className="text-center text-[#C9D41D] mt-2 text-sm">
            <Link to="/forgot-password">Wachtwoord vergeten</Link>
          </div>
        </div>
      </div>
      {showErrorModal.isOpen && (
        <AlertModal
          isOpen={showErrorModal.isOpen}
          closeModal={onHandleCloseModal}
          errorMsg={showErrorModal.errorMsg}
        />
      )}
    </>
  );
};

export default Login;
