import SearchIcon from "../../assets/search_icon.svg";
import UserIcon from "../../assets/user_icon.svg";
import LogOut from "../../assets/logout_icon.svg";
import Logo from "../../assets/logo.svg";
import { getLocalState } from "../../utils/helpers";
import {
  useAppDispatch,
  useAppSelector,
} from "../../utils/hooks/useDispatchHooks";
import { userLogOut } from "../../reduce/action/auth/AuthAction";
import { API_KEY_ID } from "../constant/urlConstant";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { SetStateAction, useEffect, useState } from "react";
import useDebounce from "../../utils/hooks/useDebounceSearch";
import { searchMagazine } from "../../reduce/action/magazine/MagazineAction";
import { clearSearchText } from "../../reduce/feature/EditionSlice";

const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showSearchInput, setShowSearchInput] = useState(false);
  const { searchMagazineList } = useAppSelector((state) => state.edition);

  const onHandleLogOut = () => {
    const userInfo = JSON.parse(getLocalState("user_info") || "");
    dispatch(
      userLogOut({
        key: API_KEY_ID,
        session_id: userInfo?.session_id,
      })
    ).then(() => {
      localStorage.clear();
      navigate("/login");
    });
  };

  const [inputValue, setInputValue] = useState("");
  const debouncedInputValue = useDebounce(inputValue, 500);

  const fetchData = async () => {
    dispatch(
      searchMagazine({
        session_id: JSON.parse(getLocalState("user_info") || "")?.session_id,
        key: API_KEY_ID,
        searchword: debouncedInputValue,
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, [debouncedInputValue]);

  const handleChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setInputValue(event.target.value);
  };

  return (
    <div className="container mx-auto w-full grid grid-cols-12 my-4 px-2 sm:px-4 lg:px-4 md:lg:px-4 gap-2 sm:gap-8">
      <div className="col-span-3 sm:col-span-2">
        <img
          onClick={() => navigate("/")}
          className="w-20 cursor-pointer"
          src={Logo}
          alt="Your Company"
        />
      </div>
      <div className="flex items-center col-span-9 sm:col-span-10 justify-end">
        <div
          className={classNames(
            "bg-[#2FB3C8]/30 rounded-full flex justify-between relative",
            { "flex-auto w-8 h-8": showSearchInput },
            {
              "w-auto": !showSearchInput,
            }
          )}
        >
          <div className="relative w-full">
            <input
              onChange={handleChange}
              type="text"
              className={classNames(
                "outline-none align-sub placeholder:text-black placeholder:text-sm",
                { "w-0 opacity-0": !showSearchInput },
                {
                  "opacity-100 xl:w-[98%] lg:w-[98%] md:w-[98%] w-[88%]  bg-transparent flex-1 px-2":
                    showSearchInput,
                }
              )}
              value={inputValue}
              placeholder="Zoek naar artikelen"
            />
            {searchMagazineList?.length > 0 ? (
              <div
                className={classNames(
                  "search_result absolute w-full max-h-56 overflow-y-auto bg-gray-200 rounded-md top-full left-0 mt-2",
                  { "w-0 opacity-0": !showSearchInput },
                  {
                    "opacity-100 w-full": showSearchInput,
                  }
                )}
              >
                <ul className="px-2">
                  {searchMagazineList?.map(
                    (
                      item: {
                        article_title: string;
                        article_subtitle: string;
                        magazine: string;
                        edition_pk: number;
                        page_number: number;
                      },
                      index: number
                    ) => (
                      <li
                        key={index}
                        onClick={() => {
                          navigate(
                            `/editions/${item?.magazine}/${item?.edition_pk}/${item?.page_number}`
                          );
                          dispatch(clearSearchText());
                          setInputValue("");
                          setShowSearchInput(!showSearchInput);
                        }}
                        className="p-2 border-b border-b-gray-300 cursor-pointer capitalize"
                      >
                        {item?.article_subtitle ? `${item?.article_title} - ${item?.article_subtitle}` : item?.article_title }
                      </li>
                    )
                  )}
                </ul>
              </div>
            ) : (
              <>
                {inputValue && (
                  <div
                    className={classNames(
                      "search_result absolute w-full max-h-56 overflow-y-auto bg-gray-200 rounded-md top-full left-0 mt-2 text-center p-6",
                      { "w-0 opacity-0": !showSearchInput },
                      {
                        "opacity-100 w-full": showSearchInput,
                      }
                    )}
                  >
                    Niet gevonden
                  </div>
                )}
              </>
            )}
          </div>
          <img
            onClick={() => setShowSearchInput(!showSearchInput)}
            className={classNames(
              "w-8 cursor-pointer",
              { "self-end": !showSearchInput },
              {
                " absolute right-0 top-0": showSearchInput,
              }
            )}
          
            src={SearchIcon}
            alt="Your Company"
          />
        </div>
        <img
          onClick={() => navigate("/profiel")}
          className="w-8 cursor-pointer ml-5"
          src={UserIcon}
          alt="profile"
        />
        <img
          onClick={onHandleLogOut}
          className="w-8 cursor-pointer ml-5"
          src={LogOut}
          alt="logout"
        />
      </div>
    </div>
  );
};

export default Header;
