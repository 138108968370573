import { combineReducers } from "@reduxjs/toolkit";
import EditionSlice from "../feature/EditionSlice";
import MagazineSlice from "../feature/MagazineSlice";

const rootReducer = combineReducers({
  magazine: MagazineSlice,
  edition: EditionSlice,
});

export default rootReducer;
