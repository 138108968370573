import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import RightArrowIcon from "../../assets/right_arrow.svg";
import { API_KEY_ID } from "../../common/constant/urlConstant";
import AlertModal from "../../common/modal/ErrorModal";
import {
  getAllMagazineList,
  getMagazines,
} from "../../reduce/action/magazine/MagazineAction";
import { getLocalState } from "../../utils/helpers";
import {
  useAppDispatch,
  useAppSelector,
} from "../../utils/hooks/useDispatchHooks";

interface Item {
  description: string,
  is_subscribed: boolean,
  is_expired: boolean,
  name: string
}

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showErrorModal, setShowErrorModal] = useState<{
    isOpen: boolean;
    errorMsg: string;
  }>({
    isOpen: false,
    errorMsg: "",
  });
  const { magazineList } = useAppSelector((state) => state.magazine);

  /* close error modal */
  const onHandleCloseModal = () => {
    setShowErrorModal({ isOpen: false, errorMsg: "" });
  };

  useEffect(() => {
    dispatch(
      getAllMagazineList({
        session_id: JSON.parse(getLocalState("user_info") || "")?.session_id,
        key: API_KEY_ID,
      })
    );
    dispatch(
      getMagazines({
        session_id: JSON.parse(getLocalState("user_info") || "")?.session_id,
        key: API_KEY_ID,
      })
    );
  }, [dispatch]);

  const handleMagzineAccess = (item: Item) => {

    if (item.name != 'InstallQ E Vakwijzer') {

      if (item.is_subscribed && !item.is_expired) {
        navigate(
          `/editions/${item?.name?.replace(/[^\w\s]/gi, "")}`
        )
      } else if ((!item.is_subscribed && item.is_expired) || (!item.is_subscribed && !item.is_expired)) {

        setShowErrorModal({
          isOpen: true,
          errorMsg:
            "U heeft geen toegang om dit tijdschrift te openen",
        })

      } else {
        setShowErrorModal({
          isOpen: true,
          errorMsg:
            "Uw abonnement is verlopen",
        })

      }

    } else {
      navigate(
        `/editions/${item?.name?.replace(/[^\w\s]/gi, "")}`
      )
    }


  }

  return (
    <>
      <div className="flex flex-col w-full">
        <div className="text-center mb-12 text-[20px]">
          Tik op een vakblad voor de edities
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 sm:w-full mx-2 sm:mx-0">
          {magazineList?.length > 0 &&
            magazineList?.map(
              (
                item: Item,
                index: number
              ) => (
                <div
                  key={index}
                  onClick={() =>
                    handleMagzineAccess(item)
                  }
                  className="border-solid border-2 border-black cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 hover:border-sky-200/30 hover:bg-sky-200/30 duration-300"
                >
                  <div className="flex sm:flex-1 flex-col items-start justify-start sm:ml-[0] p-[15px] sm:p-[30px] sm:px-5 w-auto sm:w-full">
                    <div className="flex flex-col gap-[18px] justify-start w-full">
                      <div className="flex flex-row gap-[9px] items-center justify-start w-full">
                        <img
                          className="h-3.5"
                          src={RightArrowIcon}
                          alt="arrow-right"
                        />
                        <p className="sm:text-2xl  md:text-[26px] text-[18px] text-[#206092] tracking-[0.84px]">
                          {item?.name?.replace(/[^\w\s]/gi, "")}
                        </p>
                      </div>
                      <p className="md:ml-[0] ml-[17px] text-black-900 text-[14px] sm:text-xl tracking-[0.60px]  sm:w-full">
                        {item?.description}
                      </p>
                    </div>
                  </div>
                </div>
              )
            )}
        </div>
      </div>
      {showErrorModal.isOpen && (
        <AlertModal
          isOpen={showErrorModal.isOpen}
          closeModal={onHandleCloseModal}
          errorMsg={showErrorModal.errorMsg}
        />
      )}
    </>
  );
};

export default Dashboard;
