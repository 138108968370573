import { AUTH_URLS } from '../../common/constant/urlConstant'
import { LoginIProps } from '../../components/auth/Login'
import {
  ConfirmPasswordIProps,
  ForgotPwdIProps,
  LogoutIProps,
  updatePwdIProps,
} from '../../reduce/action/auth/AuthAction'
import { apiService } from '../api-interceptor'

const AuthServices = {
  authUser: (apiData: LoginIProps) =>
    apiService.postData(`${AUTH_URLS.AUTH_LOGIN_URLS}`, apiData),
  forgotPassword: (apiData: ForgotPwdIProps) =>
    apiService.postData(`${AUTH_URLS.RESET_PASSWORD_URLS}`, apiData),
    confirmPassword: (apiData: ConfirmPasswordIProps) =>
    apiService.postData(`${AUTH_URLS.CONFIRM_PASSWORD_URLS}`, apiData),
  updatePassword: (apiData: updatePwdIProps) =>
    apiService.postData(`${AUTH_URLS.UPDATE_PASSWORD_URLS}`, apiData),
  userLogOut: (apiData: LogoutIProps) =>
    apiService.postData(`${AUTH_URLS.USER_LOGOUT_URLS}`, apiData),
  fcmToken: (apiData: { key: string; fcmToken: string }) =>
    apiService.postData(`${AUTH_URLS.FCM_TOKEN_URLS}`, apiData),
}
export default AuthServices
