import MagazineServices from "../../../services/magazine-services/MagazineServices";
import {
  getEditionDetailsLoading,
  getEditionDetailsSuccess,
  getEditionListFailure,
  getEditionListLoading,
  getEditionListSuccess,
  searchTextFailure,
  searchTextLoading,
  searchTextSuccess,
} from "../../feature/EditionSlice";
import {
  getMagazineListFailure,
  getMagazineListLoading,
  getMagazineListSuccess,
  getMagazineLoading,
  getMagazineSuccess,
} from "../../feature/MagazineSlice";

type commonIProps = {
  session_id: Number;
  key: string;
};
export interface MagazineIProps extends commonIProps {
  magazine?: string | undefined;
}

export interface EditionIProps extends commonIProps {
  edition?: string | undefined;
}

export interface SearchIProps extends commonIProps {
  searchword?: string | undefined;
}

/* get all magazineList api's */
export const getAllMagazineList =
  (apiData: MagazineIProps) => async (dispatch: any) => {
    dispatch(getMagazineLoading());
    try {
      const res = await MagazineServices.getAllMagazineList(apiData);
      dispatch(getMagazineSuccess(res?.data));
      return res?.data;
    } catch (error: any) {
      dispatch(getMagazineSuccess(error));
      return Promise.reject(error);
    }
  };

/* get all magazineList api's */
export const getMagazines =
  (apiData: MagazineIProps) => async (dispatch: any) => {
    dispatch(getMagazineListLoading());
    try {
      const res = await MagazineServices.getMagazines(apiData);
      dispatch(getMagazineListSuccess(res?.data));
      return res?.data;
    } catch (error: any) {
      dispatch(getMagazineListFailure(error));
      return Promise.reject(error);
    }
  };

/* get get editionsList api's */
export const getEditionsList =
  (apiData: MagazineIProps) => async (dispatch: any) => {
    dispatch(getEditionListLoading());
    try {
      const res = await MagazineServices.getEditionsList(apiData);
      dispatch(getEditionListSuccess(res?.data));
      return res?.data;
    } catch (error: any) {
      dispatch(getEditionListFailure(error));
      return Promise.reject(error);
    }
  };

/* get get editions details api's */
export const getEdition = (apiData: EditionIProps) => async (dispatch: any) => {
  dispatch(getEditionDetailsLoading());
  try {
    const res = await MagazineServices.getEdition(apiData);
    dispatch(getEditionDetailsSuccess(res?.data));
    return res?.data;
  } catch (error: any) {
    dispatch(getEditionDetailsSuccess(error));
    return Promise.reject(error);
  }
};

/* search magazine */
export const searchMagazine =
  (apiData: SearchIProps) => async (dispatch: any) => {
    dispatch(searchTextLoading());
    try {
      const res = await MagazineServices.searchMagazine(apiData);
      dispatch(searchTextSuccess(res?.data));
      return res?.data;
    } catch (error: any) {
      dispatch(searchTextFailure(error));
      return Promise.reject(error);
    }
  };
