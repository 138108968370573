import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { API_KEY_ID } from "../../../common/constant/urlConstant";
import AlertModal from "../../../common/modal/ErrorModal";
import { updatePassword } from "../../../reduce/action/auth/AuthAction";
import { getMagazines } from "../../../reduce/action/magazine/MagazineAction";
import { getLocalState } from "../../../utils/helpers";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../utils/hooks/useDispatchHooks";

const validationSchema = Yup.object({
  password: Yup.string()
    .required("Een wachtwoord is verplicht")
    .min(5, "Uw wachtwoord is te kort.")
    .matches(/[a-zA-Z]/, "Wachtwoord mag alleen Latijnse letters bevatten."),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "wachtwoorden moeten overeenkomen")
    .required("Bevestig Wachtwoord is vereist"),
});

const Profile: React.FC = () => {
  const dispatch = useAppDispatch();
  const { magazinesList } = useAppSelector((state) => state.magazine);
  const [showErrorModal, setShowErrorModal] = useState<{
    isOpen: boolean;
    errorMsg: string;
  }>({
    isOpen: false,
    errorMsg: "",
  });

  const onHandleSubmitForm = (values: {
    password: string;
    confirmPassword?: string;
    key?: string;
    session_id?: string;
  }) => {
    delete values.confirmPassword;
    values.key = API_KEY_ID;
    values.session_id = JSON.parse(
      getLocalState("user_info") || ""
    )?.session_id;
    dispatch(updatePassword(values)).then((res) => {
      setShowErrorModal({ isOpen: true, errorMsg: res });
      formik.resetForm();
    });
  };

  /* close error modal */
  const onHandleCloseModal = () => {
    setShowErrorModal({ isOpen: false, errorMsg: "" });
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: onHandleSubmitForm,
  });

  useEffect(() => {
    dispatch(
      getMagazines({
        session_id: JSON.parse(getLocalState("user_info") || "")?.session_id,
        key: API_KEY_ID,
      })
    );
  }, []);

  return (
    <>
      <div className="bg-white-A700 flex flex-col font-sulphurpoint items-center justify-start mx-auto py-[40px] w-full md:mx-auto sm:w-full sm:max-w-xl">
        <div className="w-full mb-4">
          <div className="">
            <label className="sm:text-[25px] text-[18px]  text-black-900 tracking-[0.75px] font-bold">
              Email:
            </label>
          </div>
          <div className="truncate pl-4">
            <p className="text-[#206092] text-[14px] sm:text-xl tracking-[0.60px] font-semibold truncate">
              {JSON.parse(getLocalState("user_info") || "")?.email}
            </p>
          </div>
        </div>
        <div className="w-full mb-10">
          <div>
            <label className="sm:text-[25px] text-[18px] text-black-900 tracking-[0.75px] font-bold">
              Abonnementen:
            </label>
          </div>
          <div className="truncate pl-4">
            {magazinesList?.length > 0 ? (
              magazinesList?.map((magazineName: string, index: number) => (
                <p
                  key={index}
                  className="text-[#206092] text-[14px] sm:text-xl tracking-[0.60px] font-semibold"
                >
                  {magazineName}
                </p>
              ))
            ) : (
              <p className="text-[#206092] text-[14px] sm:text-xl tracking-[0.60px] font-semibold">
                -
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center justify-between  md:w-full">
          <div>
            <form onSubmit={formik.handleSubmit}>
              <label className="sm:text-[25px] text-[18px] text-black-900 tracking-[0.75px] font-bold">
                Verander wachtwoord:
              </label>
              <input
                type="password"
                name="password"
                placeholder="Nieuw wachtwoord"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                className="border border-[#C9D41D] text-lg border-solid mt-2 pl-5 pr-[35px] py-3 sm:py-4 w-full"
              />
              {formik.errors.password && formik.touched.password && (
                <span className="text-[#fa5656]">{formik.errors.password}</span>
              )}
              <input
                type="password"
                name="confirmPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                placeholder="Bevestig wachtwoord"
                className="border border-[#C9D41D] text-lg border-black-900 border-solid mt-[30px] pl-5  pr-[35px] py-3 sm:py-4 w-full"
              />
              {formik.errors.confirmPassword &&
                formik.touched.confirmPassword && (
                  <span className="text-[#fa5656]">
                    {formik.errors.confirmPassword}
                  </span>
                )}

              <button
                type="submit"
                className="flex w-full mt-8 text-xl	 justify-center rounded-md bg-[#b3be1d] px-3 sm:py-4 py-3 text-sm leading-6 text-black shadow-sm hover:bg-[#cbd624]"
              >
                  Wachtwoord resetten
              </button>
            </form>
          </div>
        </div>
      </div>
      {showErrorModal.isOpen && (
        <AlertModal
          isOpen={showErrorModal.isOpen}
          closeModal={onHandleCloseModal}
          errorMsg={showErrorModal.errorMsg}
        />
      )}
    </>
  );
};

export default Profile;
