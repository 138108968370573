import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
// @ts-ignore
import { pwaInstallHandler } from "./utils/install-pwa";

import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";

import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { toast } from "react-toastify";
import { persistStoreData, store } from "./reduce/store";
import RootRoutes from "./routes";
import CloseIcon from "./assets/close.svg";

import "./App.css";

import PushWebIcon from "./assets/72x72.png";
import { API_KEY_ID } from "./common/constant/urlConstant";
import { fcmToken } from "./reduce/action/auth/AuthAction";

const firebaseConfig = {
  apiKey: "AIzaSyCUFvyYvrAfxjnDPT4XqFnEeIXQ239RPQ0",
  authDomain: "sterkindm.firebaseapp.com",
  databaseURL: "https://sterkindm.firebaseio.com",
  projectId: "sterkindm",
  storageBucket: "sterkindm.appspot.com",
  messagingSenderId: "834497737963",
  appId: "1:834497737963:web:4a30f5271b8ea16cc67aae",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

function App() {
  const [showToast, setShowToast] = useState(false);
  const [canInstall, setCanInstall] = useState(false);

  /* dynamic toast design */
  const CustomToast = ({
    title,
    body,
  }: {
    title?: string | undefined;
    body?: string | undefined;
    image?: string | undefined;
    icon?: string | undefined;
  }) => {
    return (
      <>
        <div className="grid grid-cols-5 gap-3">
          <div className="">
            <img
              src={PushWebIcon}
              className="object-contain h-14"
              alt="PushWebIcon"
            />
          </div>
          <div className="col-span-4">
            <div className="font-bold">{title}</div>
            <div className="">{body}</div>
          </div>
        </div>
      </>
    );
  };

  /* fetch fcm token using vapidKey */
  const fetchToken = () => {
    return getToken(messaging, {
      vapidKey:
        "BIeZGj6wEw5aRpWB13UhQxfcaf0i8RFubLtXB7ngPL8tXGcUWVxMbYejCZmgqrlCqvVzdr0Rjr3dKDtIvE9YEHQ",
    })
      .then((currentToken) => {
        if (currentToken) {
          store.dispatch(fcmToken({ key: API_KEY_ID, fcmToken: currentToken }));
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  };

  /* firebase dynamic toast */
  onMessage(messaging, (payload) => {
    toast(<CustomToast {...payload?.data} />, {
      icon: false,
      hideProgressBar: true,
    });
  });

  const firebaseInit = async () => {
    try {
      const isSupportedBrowser = await isSupported();
      if (isSupportedBrowser) {
        fetchToken();
      } else {
        console.log("Firebase not supported this browser");
      }
      return null;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  /* firebase init */
  useEffect(() => {
    firebaseInit();
  }, []);

  /* to show top-popup */
  useEffect(() => {
    let hasShownToast: any = sessionStorage.getItem("hasShownToast");
    if (hasShownToast !== null && hasShownToast === "true") {
      setShowToast(true);
      sessionStorage.setItem("hasShownToast", "true");
    } else if (hasShownToast !== null && hasShownToast !== "true") {
      setShowToast(false);
      sessionStorage.setItem("hasShownToast", "false");
    } else {
      setShowToast(true);
      sessionStorage.setItem("hasShownToast", "true");
    }
  }, []);

  /* dynamic toast for to show pwa install button */
  const DynamicTopToast = () => {
    return (
      <div className="flex items-center justify-between">
        <div
          className="flex items-start md:items-center gap-0 md:gap-[20px] flex-col md:flex-row relative w-[87%] md:w-full
        after:content-[''] after:border-[#00648f] after:border after:absolute after:top-[-20px] after:right-0 after:md:right-[15px] after:h-[calc(100%_+_34px)]"
        >
          <p className="max-w-[290px] text-black">
            {/* Deze site kan als applicatie worden geïnstalleerd. */}
            Deze site kan als app worden geïnstalleerd
          </p>
          <button
            className="bg-[#00648f] text-[#e5f1fd] px-3 py-2 rounded-md mt-4 md:mt-0"
            onClick={() => onHandleInstall()}
          >
            Installeren
          </button>
        </div>
        <span className="flex items-center justify-center flex-[0 0 24px]">
          <img
            className="w-6 h-6 cursor-pointer"
            src={CloseIcon}
            alt=""
            onClick={() => onHandleCloseToast()}
          />
        </span>
      </div>
    );
  };

  /* initialize pwa addListener */
  useEffect(() => {
    pwaInstallHandler.addListener(setCanInstall);
    return () => {
      pwaInstallHandler.removeListener(setCanInstall);
    };
  }, []);

  /* install app  */
  const onHandleInstall = () => {
    if (canInstall) {
      pwaInstallHandler.install();
    }
  };

  /* user close toast */
  const onHandleCloseToast = () => {
    toast.dismiss("info");
    sessionStorage.setItem("hasShownToast", "false");
  };

  return (
    <>
      {showToast && canInstall && (
        <div className="hidden">
          {toast(<DynamicTopToast />, {
            toastId: "info",
            autoClose: false,
            hideProgressBar: true,
            position: "top-center",
            closeButton: false,
            closeOnClick: false,
            draggable: false,
            theme: "colored",
            className: "dynamic-toast",
          })}
        </div>
      )}
      <BrowserRouter>
        <PersistGate loading={null} persistor={persistStoreData}>
          <Provider store={store}>
            <RootRoutes />
          </Provider>
        </PersistGate>
      </BrowserRouter>
    </>
  );
}

export default App;
