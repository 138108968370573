import { Link, Navigate, useNavigate } from "react-router-dom";
import { getLocalState } from "../../utils/helpers";
import * as Yup from "yup";
import { useFormik } from "formik";

import Logo from "../../assets/logo.svg";
import { useAppDispatch } from "../../utils/hooks/useDispatchHooks";
import { forgotPassword } from "../../reduce/action/auth/AuthAction";
import { API_KEY_ID } from "../../common/constant/urlConstant";
import ErrorModal from "../../common/modal/ErrorModal";
import { useState } from "react";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Voer een geldig e-mailadres in!")
    .required("E-mail is een verplicht veld!"),
});

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showErrorModal, setShowErrorModal] = useState<{
    isOpen: boolean;
    errorMsg: string;
  }>({
    isOpen: false,
    errorMsg: "",
  });
  const onHandleSubmitForm = (values: { email: string; key?: string }) => {
    values.key = API_KEY_ID;
    dispatch(forgotPassword(values))
      .then(() => {
        navigate("/login");
      })
      .catch((res) => {
        setShowErrorModal({ isOpen: true, errorMsg: res?.response?.data });
      });
  };

  /* close error modal */
  const onHandleCloseModal = () => {
    setShowErrorModal({ isOpen: false, errorMsg: "" });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: onHandleSubmitForm,
  });

  if (getLocalState("access_token")) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <div className="flex h-screen flex-col justify-center px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-auto w-auto"
            src={Logo}
            alt="Your Company"
          />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md bg-[#206092] px-3 rounded py-8">
          <h2 className="text-center mb-7 text-4xl tracking-tight text-white">
            Wachtwoord resetten
          </h2>
          <form onSubmit={formik.handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-white label-required"
              >
                Email
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  className="block px-3 w-full rounded-md border-0 sm:py-2.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
                />
              </div>
              {formik.errors.email && formik.touched.email && (
                <span className="text-[#FF8585]">{formik.errors.email}</span>
              )}
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-[#b6c123] px-3 sm:py-2.5 py-1.5 text-sm leading-6 text-black shadow-sm hover:bg-[#cbd624] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Wachtwoord resetten
              </button>
            </div>
          </form>
          <div className="text-center text-[#C9D41D] mt-2 text-sm">
            <Link to="/login">Terug naar Inloggen</Link>
          </div>
        </div>
      </div>
      {showErrorModal.isOpen && (
        <ErrorModal
          isOpen={showErrorModal.isOpen}
          closeModal={onHandleCloseModal}
          errorMsg={showErrorModal.errorMsg}
        />
      )}
    </>
  );
};

export default ForgotPassword;
