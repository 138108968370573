import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

const AlertModal = ({
  isOpen,
  closeModal,
  errorMsg,
}: {
  isOpen: boolean;
  closeModal: () => void;
  errorMsg: string;
}) => {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Opgelet
                  </Dialog.Title>
                  <div className="my-8">
                    <p className="text-md text-gray-500 first-letter:uppercase">
                      {errorMsg}
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="w-full uppercase inline-flex justify-center rounded-md border border-transparent bg-[#b3be1d] px-3 sm:py-2.5 py-1.5 text-sm leading-6 text-black shadow-sm hover:bg-[#cbd624]  font-medium focus:outline-none"
                      onClick={closeModal}
                    >
                      OK
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AlertModal;
