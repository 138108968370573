import AuthServices from '../../../services/auth-service/AuthService'
import { setLocalState } from '../../../utils/helpers'

export interface LoginIProps {
  email: string
  password: string
}

export interface LogoutIProps {
  session_id: Number
  key: string
}

export interface ForgotPwdIProps {
  email: string
  key?: string
}

export interface ConfirmPasswordIProps {
  new_password: string
  uid: string,
  token:string
}

export interface updatePwdIProps {
  password: string
  key?: string
  session_id?: string
}

/* user login api's */
export const authUser = (apiData: LoginIProps) => async (dispatch: any) => {
  try {
    const res = await AuthServices.authUser(apiData)
    saveTokens({
      user_info: res?.data,
    })
    return res?.data
  } catch (error) {
    return Promise.reject(error)
  }
}


/* confirm password api's */
export const confirmPassword = (apiData: ConfirmPasswordIProps) => async (
  dispatch: any,
) => {
  try {
    const res = await AuthServices.confirmPassword(apiData)
    return res?.data
  } catch (error) {
    return Promise.reject(error)
  }
}


/* reset password api's */
export const forgotPassword = (apiData: ForgotPwdIProps) => async (
  dispatch: any,
) => {
  try {
    const res = await AuthServices.forgotPassword(apiData)
    return res?.data
  } catch (error) {
    return Promise.reject(error)
  }
}

/* reset password api's */
export const updatePassword = (apiData: updatePwdIProps) => async (
  dispatch: any,
) => {
  try {
    const res = await AuthServices.updatePassword(apiData)
    return res?.data
  } catch (error) {
    return Promise.reject(error)
  }
}

/* reset password api's */
export const fcmToken = (apiData: { key: string; fcmToken: string }) => async (
  dispatch: any,
) => {
  try {
    const res = await AuthServices.fcmToken(apiData)
    return res?.data
  } catch (error) {
    return Promise.reject(error)
  }
}

/* user logout api's */
export const userLogOut = (apiData: LogoutIProps) => async (dispatch: any) => {
  try {
    const res = await AuthServices.userLogOut(apiData)
    return res?.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const saveTokens = (params: { user_info: string }) => {
  const { user_info } = params
  setLocalState('user_info', JSON.stringify(user_info))
}
