import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import Login from "../components/auth/Login";
import ForgotPassword from "../components/auth/ForgotPassword";

import Dashboard from "../components/dashboard";
import Layout from "../common/Layout/Layout";
import Profile from "../components/dashboard/profile";
import Editions from "../components/dashboard/editions";
import EditionsDetails from "../components/dashboard/editions/edition-details";
import { toast } from "react-toastify";
import ConfirmPassword from "../components/auth/ConfirmPassword";

const RootRoutes = () => {
  
  return (
    <>
     
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:uid/:token" element={<ConfirmPassword />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path="/profiel" element={<Profile />} />
            <Route path="/editions/:magazineName" element={<Editions />} />
            <Route
              path="/editions/:magazineName/:editionNumber"
              element={<EditionsDetails />}
            />
            <Route
              path="/editions/:magazineName/:editionNumber/:page_number"
              element={<EditionsDetails />}
            />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
};

export default RootRoutes;
