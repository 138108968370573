import { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API_KEY_ID, BASE_URL } from "../../../common/constant/urlConstant";
import { getEdition } from "../../../reduce/action/magazine/MagazineAction";
import { getLocalState } from "../../../utils/helpers";

import LeftArrow from "../../../assets/left_arrow.svg";
import RightArrow from "../../../assets/right_arrow_pdf.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../utils/hooks/useDispatchHooks";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const EditionsDetails = () => {
  const { editionNumber, page_number } = useParams<{
    editionNumber: string;
    page_number: string;
  }>();
  const { editionDetails } = useAppSelector((state) => state.edition);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const pathName = location.pathname.toString();

  const navigate = useNavigate();
  const pdfRef = useRef<any>();
  const [dynamicHeightWidth, setDynamicHeightWidth] = useState({
    height: 0,
    width: 0,
  });

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const nextPage = () => {
    if (pageNumber < numPages) {
      if (page_number) {
        navigate(
          `${pathName?.split("/")?.slice(0, -1)?.join("/")}/${pageNumber + 1}`,
          { replace: true }
        );
      } else {
        navigate(`${pathName}/${pageNumber + 1}`, { replace: true });
      }
      setPageNumber(pageNumber + 1);
    }
  };

  const prevPage = () => {
    if (pageNumber > 1) {
      if (page_number) {
        navigate(
          `${pathName?.split("/")?.slice(0, -1)?.join("/")}/${pageNumber - 1}`,
          { replace: true }
        );
      } else {
        navigate(`${pathName}/${pageNumber - 1}`, { replace: true });
      }
      setPageNumber(pageNumber - 1);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    dispatch(
      getEdition({
        session_id: JSON.parse(getLocalState("user_info") || "")?.session_id,
        key: API_KEY_ID,
        edition: editionNumber,
      })
    );
  }, [dispatch, editionNumber, pageNumber]);

  // search by page number
  useEffect(() => {
    if (page_number) setPageNumber(Number(page_number));
  }, [page_number]);

  // disabled right menu
  useEffect(() => {
    const handleContextMenu = (e: { preventDefault: () => void }) => {
      e.preventDefault();
    };
    window.addEventListener("contextmenu", handleContextMenu);
    return () => {
      window.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  useEffect(() => {
    const onHandleResizeWindow = () => {
      setDynamicHeightWidth({
        height: pdfRef?.current?.clientHeight,
        width: pdfRef?.current?.clientWidth - 200,
      });
    };
    window.addEventListener("resize", onHandleResizeWindow);
    onHandleResizeWindow();
    return () => {
      window.removeEventListener("resize", onHandleResizeWindow);
    };
  }, [pdfRef?.current?.clientHeight, pdfRef?.current?.clientWidth]);

  return (
    <>
      <div
        className="flex flex-col font-sulphurpoint justify-start mx-auto w-full"
        ref={pdfRef}
      >
        <div className="flex flex-col items-end w-full">
          <p className="text-sm md:text-[22px] text-black-900 text-center sm:text-xl tracking-[0.72px] ">
            {pageNumber}/{numPages}
          </p>
        </div>
        <div className="flex flex-col items-center mt-2.5 w-full">
          <div className="flex flex-row md:gap-10 items-center justify-center ">
            {pageNumber > 1 && numPages > 0 ? (
              <button
                onClick={prevPage}
                className="drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] p-2 rounded-md bg-white hidden sm:block border border-black"
              >
                <img
                  className="max-w-none sm:w-8 w-4"
                  src={LeftArrow}
                  alt="arrow-left"
                />
              </button>
            ) : (
              <>
                <div className="sm:p-6"></div>
              </>
            )}
            <div className="w-full pdf-container mx-0 sm:mx-auto h-[calc(100vh_-_240px)] overflow-auto">
              <Document
                file={`${BASE_URL}${editionDetails?.pdf}`}
                onLoadSuccess={onDocumentLoadSuccess}
                loading=""
                noData=""
              >
                <Page
                  height={dynamicHeightWidth?.height}
                  width={dynamicHeightWidth?.width}
                  loading=""
                  pageNumber={pageNumber}
                  renderTextLayer={false}
                />
              </Document>
            </div>
            {numPages > 1 &&
              numPages > Number(page_number ? page_number : 0) && (
                <button
                  onClick={nextPage}
                  className="drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] p-2 rounded-md bg-white hidden sm:block border border-black"
                >
                  <img
                    className="max-w-none sm:w-8 w-4"
                    src={RightArrow}
                    alt="arrow-right"
                  />
                </button>
              )}
          </div>
        </div>
        {numPages > 0 && (
          <div className="text-center space-x-2 my-4 sm:hidden">
            {pageNumber > 1 && numPages > 0 && (
              <button
                onClick={prevPage}
                className="drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] p-2 rounded-md bg-white border border-black"
              >
                <img
                  className="max-w-none sm:w-8 w-4"
                  src={LeftArrow}
                  alt="arrow-left"
                />
              </button>
            )}
            {numPages > 1 &&
              numPages > Number(page_number ? page_number : 0) && (
                <button
                  onClick={nextPage}
                  className="drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] p-2 rounded-md bg-white border border-black"
                >
                  <img
                    className="max-w-none sm:w-8 w-4"
                    src={RightArrow}
                    alt="arrow-right"
                  />
                </button>
              )}
          </div>
        )}
      </div>
    </>
  );
};

export default EditionsDetails;
