import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EditionIProps {
  editionList: any;
  editionDetails: any;
  searchMagazineList: any;
  status: string;
  isLoading: boolean;
  error?: string;
}

const initialState = {
  isLoading: true,
  editionList: [],
  editionDetails: {},
  searchMagazineList: [],
  error: "",
  status: "",
} as EditionIProps;

const editionSlice = createSlice({
  name: "magazineSlice",
  initialState,
  reducers: {
    getEditionListLoading(state) {
      state.status = "Pending";
    },
    getEditionListSuccess(state, action: PayloadAction<{}>) {
      state.isLoading = false;
      state.status = "Success";
      state.editionList = action.payload;
    },
    getEditionListFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.status = "Failed";
      state.error = action.payload;
    },
    getEditionDetailsLoading(state) {
      state.status = "Pending";
    },
    getEditionDetailsSuccess(state, action: PayloadAction<{}>) {
      state.isLoading = false;
      state.status = "Success";
      state.editionDetails = action.payload;
    },
    getEditionDetailsFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.status = "Failed";
      state.error = action.payload;
    },
    searchTextLoading(state) {
      state.status = "Pending";
    },
    searchTextSuccess(state, action: PayloadAction<{}>) {
      state.isLoading = false;
      state.status = "Success";
      state.searchMagazineList = action.payload;
    },
    searchTextFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.status = "Failed";
      state.error = action.payload;
    },
    clearSearchText(state) {
      state.searchMagazineList = [];
    },
  },
});

export const {
  getEditionListLoading,
  getEditionListSuccess,
  getEditionListFailure,
  getEditionDetailsLoading,
  getEditionDetailsSuccess,
  getEditionDetailsFailure,
  searchTextLoading,
  searchTextSuccess,
  searchTextFailure,
  clearSearchText
} = editionSlice.actions;

export default editionSlice.reducer;
