import { Navigate, useNavigate, useParams } from "react-router-dom";
import { getLocalState } from "../../utils/helpers";
import * as Yup from "yup";
import { useFormik } from "formik";
import Logo from "../../assets/logo.svg";
import { useAppDispatch } from "../../utils/hooks/useDispatchHooks";
import { ConfirmPasswordIProps, confirmPassword } from "../../reduce/action/auth/AuthAction";
import ErrorModal from "../../common/modal/ErrorModal";
import { useState } from "react";

const ConfirmPassword = () => {
  const { uid, token } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showErrorModal, setShowErrorModal] = useState<{
    isOpen: boolean;
    errorMsg: string;
  }>({
    isOpen: false,
    errorMsg: "",
  });


  const onHandleSubmitForm = (values: { password: string; confirmPassword:string }) => {

    console.log(values)
    if (token && uid){
        var data :ConfirmPasswordIProps = {token : token, uid:uid ,new_password:values.password} 
        dispatch(confirmPassword(data))
        .then(() => {
          navigate("/login");
        })
        .catch((res) => {
          setShowErrorModal({ isOpen: true, errorMsg: res?.response?.data });
        });
    }
  };

  /* close error modal */
  const onHandleCloseModal = () => {
    setShowErrorModal({ isOpen: false, errorMsg: "" });
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Een wachtwoord is verplicht.'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'wachtwoorden moeten overeenkomen.')
        .required('Bevestig wachtwoord is vereist.'),
    }),
    onSubmit:onHandleSubmitForm
  });


  if (getLocalState("access_token")) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <div className="flex h-screen flex-col justify-center px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-auto w-auto"
            src={Logo}
            alt="Your Company"
          />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md bg-[#206092] px-3 rounded py-8">
          <h2 className="text-center mb-7 text-4xl tracking-tight text-white">
            Wachtwoord resetten
          </h2>
          <form onSubmit={formik.handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-white label-required"
              >
                Wachtwoord
              </label>
              <div className="my-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Wachtwoord"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  className="block px-3 w-full rounded-md border-0 sm:py-2.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
                />
              </div>


              {formik.errors?.password && formik.touched?.password && (
                <span className="text-[#FF8585]">{formik.errors?.password}</span>
              )}



              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium leading-6 text-white label-required"
              >
              Herhaal  Wachtwoord
              </label>

              <div className="mt-2">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  placeholder="Herhaal watchwoord"
                  onChange={formik.handleChange}
                  value={formik.values.confirmPassword}
                  className="block px-3 w-full rounded-md border-0 sm:py-2.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
                />
              </div>

              {formik.errors.confirmPassword && formik.touched.confirmPassword && (
                <span className="text-[#FF8585]">{formik.errors.confirmPassword}</span>
              )}


            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-[#b6c123] px-3 sm:py-2.5 py-1.5 text-sm leading-6 text-black shadow-sm hover:bg-[#cbd624] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Opslaan
              </button>
            </div>
          </form>

        </div>
      </div>
      {showErrorModal.isOpen && (
        <ErrorModal
          isOpen={showErrorModal.isOpen}
          closeModal={onHandleCloseModal}
          errorMsg={showErrorModal.errorMsg}
        />
      )}
    </>
  );
};

export default ConfirmPassword;