import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MagazineIProps {
  magazineList: any;
  magazinesList:any;
  status: string;
  isLoading: boolean;
  error?: string;
}

const initialState = {
  isLoading: true,
  magazineList: [],
  magazinesList: [],
  error: "",
  status: "",
} as MagazineIProps;

const magazineSlice = createSlice({
  name: "magazineSlice",
  initialState,
  reducers: {
    getMagazineLoading(state) {
      state.status = "Pending";
    },
    getMagazineSuccess(state, action: PayloadAction<{}>) {
      state.isLoading = false;
      state.status = "Success";
      state.magazineList = action.payload;
    },
    getMagazineFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.status = "Failed";
      state.error = action.payload;
    },
    getMagazineListLoading(state) {
      state.status = "Pending";
    },
    getMagazineListSuccess(state, action: PayloadAction<{}>) {
      state.isLoading = false;
      state.status = "Success";
      state.magazinesList = action.payload;
    },
    getMagazineListFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.status = "Failed";
      state.error = action.payload;
    },
  },
});

export const {
  getMagazineLoading,
  getMagazineSuccess,
  getMagazineFailure,
  getMagazineListLoading,
  getMagazineListSuccess,
  getMagazineListFailure,
} = magazineSlice.actions;

export default magazineSlice.reducer;
